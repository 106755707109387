import { Container } from "components/container";
import classNames from "classnames";
import { Cloudinary } from "components/cloudinary";
import { unescape } from "helpers/text-processing";
import { Card } from "components/card";
import { Slider } from "components/slider";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import {
  CloudinaryItemProps,
  CloudinaryProps,
  ContentBannerEntryProps,
  ContentBannerWrapperProps,
} from "constants/types";
import { TipTapComponent } from "../text";
import _debounce from "lodash/debounce";
import {
  calculateWidthInPixels,
  createClipPathPolygon,
} from "helpers/clipPath";

const ContentBannerSlide: FC<ContentBannerEntryProps> = (props) => {
  const [clipPath, setClipPath] = useState<string>("");
  const [eyeClipPath, setEyeClipPath] = useState<string>("");
  const contentRef = useRef<HTMLDivElement>(null);
  const eyecatcherRef = useRef<HTMLDivElement>(null);
  const [eyecatcherVisible, setEyecatcherVisible] = useState<boolean>(false);
  const [clipPathOffset, setClipPathOffset] = useState<number>(0);
  const [elementHeight, setElementHeight] = useState<number>(0);

  const color = useMemo(() => {
    switch (props.background_color as string) {
      case "white": {
        return "blue";
      }
      default: {
        return "white";
      }
    }
  }, [props.background_color]);

  const hasBackground = useMemo(() => {
    return !!props?.select_image;
  }, [props.select_image]);

  const imageLeft = useMemo(() => props.image_position === "left", []);

  useEffect(() => {
    const updateClipPath = () => {
      if (imageLeft) {
        setClipPath(
          createClipPathPolygon(contentRef.current, 8, "end", "up-right"),
        );
      } else {
        setClipPath(
          createClipPathPolygon(contentRef.current, 8, "start", "up-right"),
        );
      }

      if (eyecatcherRef.current) {
        const eyeClipPath = createClipPathPolygon(
          eyecatcherRef.current,
          8,
          "start",
          "up-right",
        );
        setEyeClipPath(eyeClipPath);
      }
    };

    if (contentRef.current) {
      updateClipPath();

      window.addEventListener("resize", _debounce(updateClipPath, 200));

      return () => {
        window.removeEventListener("resize", _debounce(updateClipPath, 200));
      };
    }
  }, [contentRef, eyecatcherRef, imageLeft]);

  useEffect(() => {
    eyeClipPath !== "" && setEyecatcherVisible(true);
  }, [eyeClipPath]);

  useEffect(() => {
    const height = props.fixed_height || elementHeight;
    setClipPathOffset(calculateWidthInPixels(height));
  }, [props.fixed_height, elementHeight]);

  const calculatePadding = (offset: number, isGrid: boolean) => {
    if (isGrid) return undefined;
    return `calc(10px + ${offset}px)`;
  };

  useEffect(() => {
    const updateHeight = () => {
      if (contentRef.current) {
        const height = getComputedStyle(contentRef.current).height;
        const newHeight = Math.floor(Number(height.split("px")[0]));
        if (newHeight !== elementHeight) {
          setElementHeight(newHeight);
        }
      }
    };

    const handleResize = _debounce(() => {
      updateHeight();
    }, 200);

    updateHeight();

    // Event-Listener für Resizing
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [elementHeight]);

  const gridContent = props.partOfGrid && !props.fixed_height;
  const imageWidth = Number(props?.image_width) || 0;

  const contentStyles = {
    clipPath: clipPath,
    ...(hasBackground && {
      width: `${100 - imageWidth}%`,
    }),
    justifySelf: imageLeft && !gridContent ? "flex-end" : "initial",
    ...(imageLeft || !gridContent
      ? {
          [imageLeft ? "paddingLeft" : "paddingRight"]: calculatePadding(
            clipPathOffset,
            gridContent,
          ),
        }
      : {}),
  };

  return (
    <Container
      className={classNames("my-4", "section", "contentbanner__container")}
      id={`component__contentbanner-${props.entity_or_text}`}
    >
      <div
        className={classNames(
          "contentbanner-disruptor",
          gridContent && "grid-disruptor",
        )}
        style={{
          ...(props.fixed_height && {
            gridTemplateRows: props.fixed_height
              ? `${props.fixed_height}px`
              : 1000,
            height: `${props.fixed_height}px`,
          }),
        }}
      >
        {props.eye_catcher && (
          <div
            ref={eyecatcherRef}
            style={{
              clipPath: eyeClipPath,
              visibility: eyecatcherVisible ? "visible" : "hidden",
            }}
            className={classNames(
              "contentbanner-eyecatcher",
              "background-gradient-secondary",
            )}
          >
            {props.eye_catcher}
          </div>
        )}
        <div
          className={classNames(
            "contentbanner-child",
            "contentbanner-content",
            color,
            `bg_${props.background_color}`,
            imageLeft && "content-right",
            !hasBackground && "no-background",
            gridContent && "grid-content",
          )}
          ref={contentRef}
          style={contentStyles}
        >
          {props.entity_or_text === "entity" ? (
            <Card
              key={props?.select_entity?.id}
              title={props?.select_entity?.title}
              featuredLogos={
                "featured_logos" in props.select_entity &&
                props?.select_entity?.featured_logos?.length > 0 &&
                props.select_entity.featured_logos
                  .slice(0, 2)
                  .map((featuredLogo) => ({
                    media: featuredLogo,
                    width: 80,
                    height: 60,
                  }))
              }
              cardImg={
                props?.select_entity?.key_visual?.length > 0 && {
                  media: props.select_entity.key_visual?.[0],
                  ar: "ar43",
                  background: "grey-triangle",
                  width: 430,
                  height: 323,
                }
              }
              variant="vertical"
            >
              <p className="">{unescape(props?.select_entity?.subtitle)}</p>
              <TipTapComponent tree={props?.text_content} />
            </Card>
          ) : (
            <Card
              title={props?.headline}
              cardImg={
                props?.entry_image &&
                ({
                  media: props.entry_image,
                  ar: "ar43",
                  background: "grey-triangle",
                  width: 430,
                  height: 323,
                } as CloudinaryItemProps)
              }
              variant="vertical"
            >
              <TipTapComponent tree={props?.text_content} />
            </Card>
          )}
        </div>
        {props?.select_image && (
          <div
            className={classNames(
              "contentbanner-child",
              "contentbanner-image",
              gridContent && "grid-image",
            )}
            style={{
              [imageLeft ? "paddingRight" : "paddingLeft"]: `calc(${
                100 - +props?.image_width
              }% - ${clipPathOffset}px - 2px)`,
            }}
          >
            <Cloudinary
              media={props.select_image as unknown as CloudinaryProps}
              ar="ar169"
            />
          </div>
        )}
      </div>
    </Container>
  );
};

const ContentBannerWrapper: FC<ContentBannerWrapperProps> = (props) => {
  return (
    <Slider
      slidesPerView={1}
      autoplay={{ delay: 9993000 }}
      navigation={props.partOfGrid ? "container" : "default"}
      spaceBetween={0}
      className={classNames(
        "contentbanner__slider",
        props.partOfGrid && "grid-slider",
      )}
    >
      {props.entry_list
        .filter((entry) =>
          entry.enabled && entry.entity_or_text === "entity"
            ? entry?.select_entity
            : true,
        )
        .map((entry, index) => {
          return (
            <ContentBannerSlide
              key={index}
              {...entry}
              {...(props.fix_slider_height &&
                props.fixed_height && { fixed_height: props.fixed_height })}
              partOfGrid={props.partOfGrid}
            />
          );
        })}
    </Slider>
  );
};

export default ContentBannerWrapper;
